import React from 'react';
import { useLabels, usePrefs, useFlags } from 'wsm-common-data';
import { useSelector } from 'react-redux';
import logger from 'ws-scripts/modules/logger';
import { ErrorBoundary } from 'wsm-error-boundary';
import { loadingSelector, inventoryCountsSelector } from '../server/selectors';

import { useGetVehicles } from '../hooks/useGetVehicles';
import { useFetchInventoryCounts } from '../hooks/useFetchInventoryCounts';
import { TabbedBodyStyles } from '../components/TabbedBodyStyles';
import { Loading } from '../components/Loading';
import { Error } from '../components/Error';

import '../global-sass/main.scss';

const WidgetContainer = () => {
	const labels = useLabels();
	const loading = useSelector(loadingSelector);
	const vehicles = useGetVehicles();
	const inventoryCounts = useSelector(inventoryCountsSelector);
	const { excludeModelsWithoutInventory, showInventoryOnLotCount } =
		usePrefs();
	const flags = useFlags();
	const enableModelSelectorService = flags['enable-model-selector-service'];
	// If excludeModelsWithoutInventory is true and no model
	// has inventory, we should not render the widget
	const noInventoryAndExclude =
		enableModelSelectorService &&
		excludeModelsWithoutInventory === 'true' &&
		showInventoryOnLotCount === 'true' &&
		inventoryCounts &&
		Object.keys(inventoryCounts).length === 0;
	useFetchInventoryCounts();

	// TODO delete this loading component once the Data Bus gets deprecated
	if (loading.status && !loading.error) {
		return <Loading />;
	} else if (
		(!loading.status && loading.error) ||
		(!loading.status && noInventoryAndExclude) ||
		Object.keys(vehicles).length === 0
	) {
		return <Error message={labels.get('MODEL_SELECTOR_UNAVAILABLE')} />;
	}

	return (
		<ErrorBoundary
			errorMarkup={
				<Error
					message={labels.get('TECHNICAL_DIFFICULTIES_VERBIAGE')}
				/>
			}
			errorHandler={(error, errorInfo) => {
				const newError = new Error(
					`Encountered an error in ws-model-selector.\n${error}`
				);
				newError.originalError = error;
				newError.originalStackTrace = errorInfo.componentStack;
				logger.error(
					`Encountered an error in ws-model-selector.\n${error.componentStack}\n${error.message}`
				);
			}}
		>
			<TabbedBodyStyles data={vehicles} />
		</ErrorBoundary>
	);
};

export default WidgetContainer;
