/**
 * Sorts the vehicles in each group by model name.
 *
 * @param {ModelSelectorResponse} vehicles - The dictionary of vehicles grouped by type.
 * @param {string} locale - en_US, en_CA, etc.
 * @example
 * const vehicles = {
 *   suv: [
 *     {
 *       model: 'Model Y',
 *     },
 *     {
 *       model: 'Model X',
 *     },
 *   ],
 *   sedan: [
 *     {
 *       model: 'Model S',
 *     }
 *   ]
 * };
 *
 * const sortedVehicles = sortVehicleGroupsContent(vehicles, 'en_US');
 * console.log(sortedVehicles);
 * // Output:
 * // {
 * //   suv: [
 * //     {
 * //       model: 'Model X',
 * //     },
 * //     {
 * //       model: 'Model Y',
 * //     }
 * //   ],
 * //   sedan: [
 * //     {
 * //       model: 'Model S',
 * //     }
 * //   ]
 * // }
 */
export const sortVehicleGroupsContent = (vehicles, locale) => {
	const sortedVehicleGroups = {};

	Object.keys(vehicles).forEach((group) => {
		sortedVehicleGroups[group] = vehicles[group].sort((a, b) =>
			a.model.localeCompare(b.model, locale.split('_')[0])
		);
	});

	return sortedVehicleGroups;
};
