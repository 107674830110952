import { SET_INVENTORY_COUNTS } from '../actions/types';

export default function inventoryCounts(state = {}, { type, payload }) {
	switch (type) {
		case SET_INVENTORY_COUNTS:
			return { ...payload };
		default:
			return state;
	}
}
