import { SET_LOADING_INVENTORY_COUNTS } from '../actions/types';

const REDUCER_INITIAL_STATE = {
	error: null,
	status: true
};
export default function loadingInventoryCounts(
	state = REDUCER_INITIAL_STATE,
	{ type, payload }
) {
	switch (type) {
		case SET_LOADING_INVENTORY_COUNTS:
			return { ...state, ...payload };
		default:
			return state;
	}
}
