/**
 * Removes duplicated vehicles from the given vehicles dictionary.
 *
 * @param {Object} vehicles - The vehicles dictionary.
 * @returns {Array} - An array of unique vehicles.
 *
 * @example
 * const vehicles = {
 *   performance: [
 *     {
 *       gvBodyStyle: 'Convertible',
 *       make: 'Lexus',
 *       model: 'LC 500',
 *       year: 2024
 *     }],
 *    convertible: [{
 *       gvBodyStyle: 'Convertible',
 *       make: 'Lexus',
 *       model: 'LC 500',
 *       year: 2024
 *     }]
 * };
 *
 * const uniqueVehicles = removeDuplicatedVehicles(vehicles);
 * console.log(uniqueVehicles);
 * // Output:
 * // [
 * //   {
 * //     gvBodyStyle: 'Convertible',
 * //     make: 'Lexus',
 * //     model: 'LC 500',
 * //     year: 2024
 * //   }
 * // ]
 */

export const groupUniqueVehicles = (vehicles) => {
	const seen = new Set();
	return Object.values(vehicles).reduce((acc, curr) => {
		curr.forEach((vehicle) => {
			// Use make, year, model and bodyStyle as unique key
			const uniqueKey = `${vehicle.make}-${vehicle.year}-${vehicle.model}-${vehicle.gvBodyStyle}`;
			if (!seen.has(uniqueKey)) {
				seen.add(uniqueKey);
				acc.push(vehicle);
			}
		}, []);
		return acc;
	}, []);
};
