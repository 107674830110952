export function buildInventoryCountsDictionary(vehicles) {
	return Object.values(vehicles)
		.flat()
		.reduce((acc, { model, inventoryCount }) => {
			if (model) {
				acc[model] = inventoryCount;
			}
			return acc;
		}, {});
}
