import { useEffect } from 'react';
import { useFlags, usePrefs, useRequestData } from 'wsm-common-data';
import { useSelector, useDispatch } from 'react-redux';
import { isBrowser } from 'ws-scripts/modules/environment';
import { hysteriFetch, wrappers } from 'hysterics';
import logger from 'ws-scripts/modules/logger';
import { vehiclesSelector, widgetDataSelector } from '../server/selectors';
import { addUrlSearchParams } from '../utils/addUrlSearchParams';
import { setLoading } from '../actions/setLoading';
import { setVehicles } from '../actions/setVehicles';
import { setInventoryCounts } from '../actions/setInventoryCounts';
import { buildInventoryCountsDictionary } from '../utils/buildInventoryCountsDictionary';
import { getTimeouts } from '../utils/getTimeouts';

const timeout = getTimeouts('useGetVehicles');

const dataBusService = hysteriFetch('ws-model-selector-data-bus', {
	[wrappers.circuitBreaker.contextKey]: {
		timeout
	},
	[wrappers.timeout.contextKey]: {
		timeout
	}
});

// TODO delete this hook once the Data Bus gets deprecated
export const useGetVehicles = () => {
	// hooks
	const prefs = usePrefs();
	const flags = useFlags();
	const requestData = useRequestData();
	const dispatch = useDispatch();
	const vehicles = useSelector(vehiclesSelector);
	const { pageAlias } = useSelector(widgetDataSelector);

	// dependencies
	const { excludeModelsWithoutInventory, showInventoryOnLotCount } = prefs;
	const imagePerspective =
		requestData.deviceType === 'MOBILE' || prefs.displayStyle === 'grid'
			? 'side-left'
			: 'front-left';
	const enableModelSelectorService = flags['enable-model-selector-service'];

	useEffect(() => {
		const fetchVehiclesDataBus = async () => {
			const domain = isBrowser
				? window.location.origin
				: 'http://localhost';
			const path = `/apis/widget/${pageAlias}:model-selector-data-bus1/getModelSelectorData`;
			const url = new URL(path, domain);

			let searchParams;
			if (isBrowser) {
				searchParams = new URLSearchParams(window.location.search);
			} else {
				searchParams = new URLSearchParams();
			}

			const ddcpreview = searchParams.get('_ddcpreview');
			addUrlSearchParams(url.searchParams, {
				_ddcpreview: ddcpreview,
				imagePerspective,
				excludeModelsWithoutInventory,
				showInventoryOnLotCount
			});

			try {
				const urlString = url.toString();
				const vehiclesResponse = await dataBusService(urlString);
				dispatch(setVehicles(vehiclesResponse));
				dispatch(
					setInventoryCounts(
						buildInventoryCountsDictionary(vehiclesResponse)
					)
				);
			} catch (error) {
				logger.error(error.message);
				dispatch(
					setLoading({
						error
					})
				);
			} finally {
				dispatch(
					setLoading({
						status: false
					})
				);
			}
		};

		// if the model selector service isn't enabled, fetch vehicles from the databus
		if (!enableModelSelectorService) {
			fetchVehiclesDataBus();
		}
	}, [
		excludeModelsWithoutInventory,
		enableModelSelectorService,
		showInventoryOnLotCount,
		imagePerspective,
		pageAlias,
		dispatch
	]);

	return vehicles;
};
