import { SET_VEHICLES } from '../actions/types';

export default function vehicles(state = {}, { type, payload }) {
	switch (type) {
		case SET_VEHICLES:
			return { ...payload };
		default:
			return state;
	}
}
