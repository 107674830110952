import { useMemo } from 'react';
import { usePrefs } from 'wsm-common-data';

export const useBuildTabs = (vehicles) => {
	const { tabOrder, showAllModelsTab, displayStyle } = usePrefs();

	const tabNames = useMemo(() => {
		const rawTabNames = Object.keys(vehicles).filter(
			(tab) => tab !== 'All'
		);
		let newTabNames = rawTabNames;

		if (tabOrder) {
			const tabs = tabOrder.split(',');
			const orderedTabs = new Set();
			tabs.forEach((tabName) => {
				// Validate that the tab name is in the raw tab names
				// we should only add valid tabs to the orderedTabs set
				const tabToAdd = rawTabNames.find(
					(rawTabName) =>
						rawTabName.toLowerCase() ===
						tabName.trim().toLowerCase()
				);
				if (tabToAdd) {
					orderedTabs.add(tabToAdd);
				}
			});
			newTabNames = Array.from(orderedTabs);
		}
		if (showAllModelsTab === 'true' && vehicles) {
			if (displayStyle === 'largeVehicle') {
				// Add the All tab to the end of the array
				newTabNames.push('All');
			} else {
				// Add the All tab to the beginning of the array
				newTabNames.unshift('All');
			}
		}

		return newTabNames;
	}, [vehicles, tabOrder, showAllModelsTab, displayStyle]);
	return tabNames;
};
