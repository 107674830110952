import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { BodyStyleContainer } from './BodyStyleContainer';
import { useProcessVehicles } from '../hooks/useProcessVehicles';
import { useBuildTabs } from '../hooks/useBuildTabs';
import Tab from './Tab';
import Tabs from './Tabs';

export const TabbedBodyStyles = ({ data }) => {
	const tabSliderRef = React.useRef();
	const vehicles = useProcessVehicles(data);
	const tabNames = useBuildTabs(vehicles);
	const [activeTab, setActiveTab] = useState(tabNames[0]);
	const defaultTab = tabNames[0] === 'All' ? 'All' : tabNames[0];

	const getTabContent = (tabTitle) => {
		const tabVehicles = vehicles[tabTitle];
		if (tabVehicles) {
			return <BodyStyleContainer vehiclesArray={tabVehicles} />;
		} else {
			return null;
		}
	};

	const tabArray = React.Children.toArray(
		tabNames.map((tabName) => (
			<Tab
				title={tabName}
				eventKey={tabName}
				tabClassName="tab-item ddc-font-size-xsmall mx-2"
			>
				{getTabContent(tabName)}
			</Tab>
		))
	);

	return (
		<Tabs
			ref={tabSliderRef}
			activeKey={activeTab}
			onSelect={setActiveTab}
			id="tabbed-model-selector"
			defaultActiveKey={defaultTab}
		>
			{tabArray}
		</Tabs>
	);
};

TabbedBodyStyles.propTypes = {
	data: PropTypes.objectOf(
		PropTypes.arrayOf(
			PropTypes.shape({
				year: PropTypes.number,
				make: PropTypes.string,
				model: PropTypes.string,
				modelInv: PropTypes.string,
				gvBodyStyle: PropTypes.string,
				image: PropTypes.string,
				inventoryCount: PropTypes.number
			})
		)
	).isRequired
};
