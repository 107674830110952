import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { usePrefs, useRequestData } from 'wsm-common-data';
import { sortVehicleGroupsContent } from '../utils/sortVehicleGroupsContent';
import filterVehiclesWithoutInventory from '../utils/filterVehiclesWithoutInventory';
import { groupUniqueVehicles } from '../utils/groupUniqueVehicles';
import { inventoryCountsSelector } from '../server/selectors';
import { addModelInv } from '../utils/inventoryUtils';

export const useProcessVehicles = (data) => {
	const { excludeModelsWithoutInventory, showAllModelsTab, tabOrder } =
		usePrefs();
	const { locale } = useRequestData();
	const inventoryCounts = useSelector(inventoryCountsSelector);

	return useMemo(() => {
		let processedVehicles;
		if (
			excludeModelsWithoutInventory === 'true' &&
			inventoryCounts &&
			Object.keys(inventoryCounts).length
		) {
			processedVehicles = filterVehiclesWithoutInventory(
				data,
				inventoryCounts
			);
		} else {
			processedVehicles = { ...data };
		}

		processedVehicles = sortVehicleGroupsContent(processedVehicles, locale);

		if (showAllModelsTab === 'true' && processedVehicles) {
			if (tabOrder) {
				const tabs = tabOrder.split(',');
				const orderedVehicles = {};
				tabs.forEach((tab) => {
					if (processedVehicles[tab]) {
						orderedVehicles[tab] = processedVehicles[tab];
					}
				});
				processedVehicles = orderedVehicles;
			}

			const allCars = groupUniqueVehicles(processedVehicles);
			processedVehicles.All = [...allCars];
		}

		processedVehicles = addModelInv(processedVehicles, inventoryCounts);

		return processedVehicles;
	}, [
		data,
		inventoryCounts,
		excludeModelsWithoutInventory,
		locale,
		showAllModelsTab,
		tabOrder
	]);
};
