import { getKeysIgnoreCase } from './IgnoreMapCasing';

export const addModelInv = (vehicles, inventoryCounts) => {
	const hasInventory =
		Boolean(inventoryCounts) && Object.keys(inventoryCounts).length > 0;
	return Object.keys(vehicles).reduce((acc, group) => {
		acc[group] = vehicles[group].map((vehicle) => ({
			...vehicle,
			modelInv: hasInventory
				? getKeysIgnoreCase(inventoryCounts, vehicle.model)
				: vehicle.model
		}));
		return acc;
	}, {});
};
