import React from 'react';
import PropTypes from 'prop-types';
import { usePrefs, useRequestData } from 'wsm-common-data';

import GridContainer from './displayStyles/Grid';
import CarouselContainer from './displayStyles/Carousel';
import LargeVehicleContainer from './displayStyles/Large';
import MobileCarousel from './displayStyles/MobileCarousel';

export const BodyStyleContainer = ({ vehiclesArray }) => {
	const { deviceType } = useRequestData();
	const { displayStyle } = usePrefs();

	const props = {
		vehicles: vehiclesArray
	};

	const displayStyles = {
		grid: GridContainer,
		carousel: CarouselContainer,
		largeVehicle: LargeVehicleContainer
	};

	let ComponentToRender;

	if (deviceType === 'MOBILE') {
		ComponentToRender = MobileCarousel;
	} else {
		ComponentToRender = displayStyles[displayStyle] || GridContainer;
	}

	return (
		<div data-testid="tabbed-model-selector">
			<ComponentToRender {...props} />
		</div>
	);
};

BodyStyleContainer.propTypes = {
	vehiclesArray: PropTypes.arrayOf(
		PropTypes.shape({
			year: PropTypes.number,
			make: PropTypes.string,
			model: PropTypes.string,
			modelInv: PropTypes.string,
			gvBodyStyle: PropTypes.string,
			image: PropTypes.string,
			inventoryCount: PropTypes.number,
			minStartingAtPrice: PropTypes.number
		})
	).isRequired
};

BodyStyleContainer.defaultProps = {};
