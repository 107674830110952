import { useEffect } from 'react';
import { useFlags, usePrefs, useRequestData } from 'wsm-common-data';
import { useSelector, useDispatch } from 'react-redux';
import { isBrowser } from 'ws-scripts/modules/environment';
import { hysteriFetch, wrappers } from 'hysterics';
import logger from 'ws-scripts/modules/logger';
import { widgetDataSelector, loadingSelector } from '../server/selectors';
import { addUrlSearchParams } from '../utils/addUrlSearchParams';
import { setLoading } from '../actions/setLoading';
import { setLoadingInventoryCounts } from '../actions/setLoadingInventoryCounts';
import { setInventoryCounts } from '../actions/setInventoryCounts';
import { getTimeouts } from '../utils/getTimeouts';

const timeout = getTimeouts('useFetchInventoryCounts');

const mssService = hysteriFetch('ws-model-selector-data-bus', {
	[wrappers.retry.contextKey]: {
		retries: 0
	},
	[wrappers.circuitBreaker.contextKey]: {
		timeout
	},
	[wrappers.timeout.contextKey]: {
		timeout
	}
});

// TODO delete this hook once the Data Bus gets deprecated
export const useFetchInventoryCounts = () => {
	// hooks
	const prefs = usePrefs();
	const flags = useFlags();
	const requestData = useRequestData();
	const dispatch = useDispatch();
	const loadingVehicles = useSelector(loadingSelector);
	const { siteId, accountId, pageAlias, pageId, make, host } =
		useSelector(widgetDataSelector);

	// dependencies
	const { windowId, widgetName, deviceType, locale } = requestData;
	const {
		excludeModelsWithoutInventory,
		showInventoryOnLotCount,
		enableGroupInventoryConstraints
	} = prefs;
	const enableModelSelectorService = flags['enable-model-selector-service'];

	useEffect(() => {
		const fetchInventoryCounts = async () => {
			const domain = isBrowser
				? window.location.origin
				: 'http://localhost';
			const path = '/api/widget/ws-model-selector/inventory-counts';
			const url = new URL(path, domain);

			addUrlSearchParams(url.searchParams, {
				siteId,
				pageAlias,
				pageId,
				windowId,
				widgetName,
				referrer: isBrowser ? window.location.origin : '',
				host,
				uri: isBrowser ? window.location.pathname : '',
				make,
				device: deviceType,
				locale,
				accountId,
				enableGroupInventoryConstraints
			});

			try {
				const urlString = url.toString();
				const invCountsResponse = await mssService(urlString);
				dispatch(setInventoryCounts(invCountsResponse));
			} catch (error) {
				logger.error(error.message);
				dispatch(
					setLoadingInventoryCounts({
						error
					})
				);
			} finally {
				dispatch(
					setLoadingInventoryCounts({
						status: false
					})
				);
				dispatch(
					setLoading({
						status: false
					})
				);
			}
		};

		if (
			enableModelSelectorService &&
			showInventoryOnLotCount === 'true' &&
			!loadingVehicles.error
		) {
			// if the model selector service isn't enabled, fetch vehicles from the databus
			fetchInventoryCounts();
		}
	}, [
		dispatch,
		enableModelSelectorService,
		showInventoryOnLotCount,
		excludeModelsWithoutInventory,
		siteId,
		pageAlias,
		pageId,
		windowId,
		widgetName,
		make,
		host,
		deviceType,
		locale,
		accountId,
		loadingVehicles.error,
		enableGroupInventoryConstraints
	]);
};
