import { isBrowser } from 'ws-scripts/modules/environment';

const timeoutsDictionary = {
	useFetchInventoryCounts: {
		localhost: 4000,
		prod: 2200
	},
	useGetVehicles: {
		localhost: 5000,
		prod: 1000
	}
};

export const getTimeouts = (hookName) => {
	if (!timeoutsDictionary[hookName]) {
		throw new Error(`Timeouts for ${hookName} not found`);
	}
	const env =
		isBrowser && window.location.hostname.includes('localhost')
			? 'localhost'
			: 'prod';
	return (
		timeoutsDictionary[hookName][env] || timeoutsDictionary[hookName].prod
	);
};
