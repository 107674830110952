import React from 'react';
import PropTypes from 'prop-types';
import { trackEvent } from 'ddc-track-event';
import { setClassNames } from 'ddc-classnames-js';
import { usePrefs, useLabels, useRequestData } from 'wsm-common-data';

import { ModelImage } from '../ModelImage';
import InventoryCount from './InventoryCount';

import { getModelTitle } from '../../utils/getModelTitle';
import { getFormattedPrice } from '../../utils/getFormattedPrice';

const LargeVehicleCard = ({ modelObject, modelLink }) => {
	const prefs = usePrefs();
	const labels = useLabels();
	const { locale, widgetName, windowId } = useRequestData();

	const { image, modelInv } = modelObject;
	const {
		modelTitleAttributes,
		startingAtPriceLabel,
		showPricing,
		includeDestinationChargeInPrice
	} = prefs;

	const modelTitleClassName = [
		'model-card-title-container',
		'font-weight-bold',
		'ddc-font-size-large',
		'text-center',
		'mx-3'
	];
	const priceClassNames = [
		'price',
		'ddc-font-size-small',
		'inline-block',
		'justify-content-start',
		'mx-3'
	];
	const modelTitle = getModelTitle(modelTitleAttributes, modelObject);
	const formattedPrice = getFormattedPrice(
		locale,
		includeDestinationChargeInPrice === 'true',
		modelObject
	);

	const trackClick = () => {
		trackEvent(widgetName, windowId, {
			action: 'clicked',
			element: 'Model Image',
			elementCTA: modelTitle,
			result: 'Navigated to model listings'
		});
	};

	return (
		<div className="large-vehicle-card model">
			<a
				href={modelLink}
				onClick={trackClick}
				className="image-container"
				data-testid="model-card-large-vehicle-component-test"
			>
				<ModelImage url={image} altImage={modelTitle} />
			</a>

			<div className="model-card-title-container text-center mt-6">
				<span className={setClassNames(modelTitleClassName)}>
					{modelTitle}
				</span>
				{showPricing === 'true' && (
					<span className={setClassNames(priceClassNames)}>
						{`${labels.get(startingAtPriceLabel)} `}{' '}
						{formattedPrice}
					</span>
				)}
			</div>
			<InventoryCount model={modelInv} />
		</div>
	);
};

LargeVehicleCard.propTypes = {
	modelObject: PropTypes.shape({
		year: PropTypes.number,
		make: PropTypes.string,
		model: PropTypes.string,
		modelInv: PropTypes.string,
		image: PropTypes.string,
		inventoryCount: PropTypes.number,
		minStartingAtPrice: PropTypes.number
	}).isRequired,
	modelLink: PropTypes.string.isRequired
};

export default LargeVehicleCard;
