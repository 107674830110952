export const getValueIgnoreCase = (obj, key) => {
	const normalizedKey = key.toLowerCase();
	return Object.entries(obj).reduce((acc, [objKey, value]) => {
		if (objKey.toLowerCase() === normalizedKey) {
			return value;
		}
		return acc;
	}, 0);
};

export const getKeysIgnoreCase = (obj, key) => {
	const normalizedKey = key.toLowerCase();
	let result = '';
	Object.entries(obj).forEach(([objKey]) => {
		if (objKey.toLowerCase() === normalizedKey) {
			result = objKey;
		}
	});
	return result;
};
