import { useState, useEffect } from 'react';
import { isBrowser } from 'ws-scripts/modules/environment';
import debounce from './debounce';

export const useDimensions = () => {
	const defaultWidth = isBrowser ? window.innerWidth : 0;
	const defaultHeight = isBrowser ? window.innerHeight : 0;
	const [screenSize, setScreenSize] = useState({
		width: defaultWidth,
		height: defaultHeight
	});

	useEffect(() => {
		const handleResize = debounce(() => {
			setScreenSize({
				width: window.innerWidth,
				height: window.innerHeight
			});
		});

		window.addEventListener('resize', handleResize);

		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	return screenSize;
};
